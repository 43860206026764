<template>
  <div class="view-home">
      <div class="events-block">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
              <li class="breadcrumbs-item">{{ $t('events-main') }}</li>
            </ul>
          </nav>
          <div class="events-title">
            {{ $t('events-main') }}
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
          <div class="events-section-title">
            {{ $t('forums') }}
          </div>
        </div>
        <div class="forums-section">
          <div class="container">
            <router-link to="/forums" class="all-forums">
              {{ $t('viewAllForums') }}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5732 6.55029L3.42681 16.4498" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6532 3.89222L20.5732 6.55029L17.9152 16.4704" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </router-link>
            <carousel :perPage="1" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="true"
                      :paginationEnabled="false" class="forum-carousel-block">
              <slide v-for="slide in eventsList" class="forum-slide-item">
                <div class="forum-slide-title">
                  {{slide[`name_${$i18n.locale}`] || slide.name_ru}}
                </div>
                <div class="forum-slide-text">
                  {{slide[`goal_${$i18n.locale}`] || slide.goal_ru}}
                </div>
                <div class="forum-slide-details">
                  <div>
                    <span>{{ $t('datesOfEvent') }}:</span> {{ formattedDate(slide.date_from, slide.date_to) }}
                  </div>
                  <div>
                    <span>{{ $t('venue') }}:</span> {{slide[`venue_${$i18n.locale}`] || slide.venue_ru }}
                  </div>
                </div>
                <router-link :to="/one-forum/ + slide.id" class="forum-slide-link">
                  {{ $t('findMore') }}
                </router-link>
              </slide>
            </carousel>
          </div>

        </div>
        <div class="trainings-section">
          <div class="container">
            <div class="trainings-section-desc">
              <div class="trainings-section-left">
                <div class="trainings-section-title">
                  {{ $t('seminarsTrainings') }}
                </div>
                <div class="trainings-section-text">
                  {{ $t('seminarsTrainingsText') }}
                </div>
              </div>
              <div class="trainings-section-right">
                <router-link to="/seminars" class="trainings-section-link">
                  {{ $t('seeAll') }}
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 5L17 12.5L9.5 20" stroke="#1090CB" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>

                </router-link>
              </div>
            </div>

            <div class="forums-list">
              <router-link :to="typeEvent(item.type, item.id)" class="forums-item" v-for="item in allInfoShow">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="getImagePath(item.img)"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  {{ $t('dateOfEvent') }}: {{formattedDate(item.start_date, item.end_date)}}
                </div>
                <div class="forums-item-title">
                  {{ item[`title_${$i18n.locale}`] || item.title_ru }}
                </div>
                <div class="forums-item-text">
                  {{ item[`description_${$i18n.locale}`] || item.description_ru }}
                  {{ item[`text_${$i18n.locale}`] || item.text_ru }}
                  {{ item[`goal_${$i18n.locale}`] || item.goal_ru }}
                </div>
                <div class="forums-item-address">
                  {{ $t('venue') }}: {{ item[`location_${$i18n.locale}`] || item.location_ru }}
                </div>
              </router-link>
            </div>

            <div class="trainings-section-desc">
              <div class="trainings-section-left">
                <div class="trainings-section-title">
                  {{ $t('interTrainingCourses') }}
                </div>
                <div class="trainings-section-text">
                  {{ $t('interTrainingCoursesText') }}
                </div>
              </div>
              <div class="trainings-section-right">
                <router-link to="/trainings" class="trainings-section-link">
                  {{ $t('seeAll') }}
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 5L17 12.5L9.5 20" stroke="#1090CB" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>

                </router-link>
              </div>
            </div>

            <div class="forums-list">
              <router-link :to="typeEvent(internationalCourse[0].type, internationalCourse[0].id)" class="forums-item" v-if="internationalCourse[0]">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="getImagePath(internationalCourse[0].img)"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  {{ $t('dateOfEvent') }}: {{formattedDate(this.internationalCourse[0].date_from, this.internationalCourse[0].date_to)}}
                </div>
                <div class="forums-item-title">
                  {{this.internationalCourse[0][`title_${$i18n.locale}`] || this.internationalCourse[0].title_ru}}
                </div>
                <div class="forums-item-text">
                  {{this.internationalCourse[0][`description_${$i18n.locale}`] || this.internationalCourse[0].description_ru}}
                </div>
                <div class="forums-item-address">
                  {{ $t('venue') }}: {{this.internationalCourse[0][`location_${$i18n.locale}`] || this.internationalCourse[0].location_ru}}
                </div>
              </router-link>
              <router-link :to="typeEvent(internationalCourse[1].type, internationalCourse[1].id)" class="forums-item" v-if="internationalCourse[1]">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="getImagePath(internationalCourse[1].img)"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  {{ $t('dateOfEvent') }}: {{formattedDate(this.internationalCourse[1].date_from, this.internationalCourse[1].date_to)}}
                </div>
                <div class="forums-item-title">
                  {{this.internationalCourse[1][`title_${$i18n.locale}`] || this.internationalCourse[1].title_ru}}
                </div>
                <div class="forums-item-text">
                  {{this.internationalCourse[1][`description_${$i18n.locale}`] || this.internationalCourse[1].description_ru}}
                </div>
                <div class="forums-item-address">
                  {{ $t('venue') }}: {{this.internationalCourse[1][`location_${$i18n.locale}`] || this.internationalCourse[1].location_ru}}
                </div>
              </router-link>
              <router-link :to="typeEvent(internationalCourse[2].type, internationalCourse[2].id)" class="forums-item" v-if="internationalCourse[2]">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="backUrl + internationalCourse[2].image"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  {{ $t('dateOfEvent') }}: {{formattedDate(this.internationalCourse[2].date_from, this.internationalCourse[2].date_to)}}
                </div>
                <div class="forums-item-title">
                  {{this.internationalCourse[2][`title_${$i18n.locale}`] || this.internationalCourse[2].title_ru}}
                </div>
                <div class="forums-item-text">
                  {{this.internationalCourse[2][`description_${$i18n.locale}`] || this.internationalCourse[2].description_ru}}
                </div>
                <div class="forums-item-address">
                  {{ $t('venue') }}: {{this.internationalCourse[2][`location_${$i18n.locale}`] || this.internationalCourse[2].location_ru}}
                </div>
              </router-link>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>
<script>

export default {
  mounted() {
    this.$http.get(window.API_ROOT + '/api/events/forum/list')
        .then(res => {
          this.eventsList = res.body
        })

    this.$http.get(window.API_ROOT + '/api/events/seminar/list')
        .then(res => {

          this.seminarList = res.body
          this.seminarList = this.seminarList.map( item => ({...item, type: 'seminar'}))
          console.log('this.seminarList', this.seminarList)
          this.allInfo.push(...this.seminarList)
          // this.allInfo.length = 3
          console.log('this.allInfo1', this.allInfo)
        })

    this.$http.get(window.API_ROOT + '/api/events/training/list')
        .then(res => {

          this.trainingList = res.body
          this.trainingList = this.trainingList.map( item => ({...item, type: 'training', title_ru: item.name_ru, title_kz: item.name_kz, title_en: item.name_en}))
          console.log('this.trainingList', this.trainingList)
          this.allInfo.push(...this.trainingList)
          // this.allInfo.length = 3
          console.log('this.allInfo2', this.allInfo)
        })

    this.$http.get(window.API_ROOT + '/api/events/refresher-course/list')
        .then(res => {

          this.refresherCourseList = res.body
          this.refresherCourseList = this.refresherCourseList.map( item => ({...item, type: 'refresher_course'}))
          console.log('this.refresherCourseList', this.refresherCourseList)

          this.allInfo.push(...this.refresherCourseList)
          // this.allInfo.length = 3
          console.log('this.allInfo3', this.allInfo)
        })

    this.$http.get(window.API_ROOT + '/api/events/international-course/list')
        .then(res => {

          this.internationalCourse = res.body
          this.internationalCourse = this.internationalCourse.map( item => ({...item, type: 'international_course'}))
          console.log('this.internationalCourse', this.internationalCourse)
        })



  },
  filters: {

  },
  computed: {
    allInfoShow() {
      return this.allInfo.slice(0, 3)
    }
  },
  methods: {
    getImagePath(path) {
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
      } else {
        return this.backUrl + path;
      }
    },
    typeEvent(type, id) {
      if (type == 'refresher_course' || type == 'international_course') {
        return `/one-training/${type}/${id}`
      } else {
        return `/one-seminar/${type}/${id}`
      }
    },
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    }
  },
  data() {
    return {
      eventsList: [],
      seminarList: [{type: ''}],
      trainingList: [{type: ''}],
      refresherCourseList: [{type: ''}],
      internationalCourse: [{type: ''}],
      allInfo: [],
      slides: [
        {
          title: "Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»",
          text: "Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.",
          date:"05.07.2024 - 07.07.2024 гг",
          address:"г. Астана, пр. Мангилик Ел, 55/20",
        },
        {
          title: "Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»",
          text: "Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.",
          date:"05.07.2024 - 07.07.2024 гг",
          address:"г. Астана, пр. Мангилик Ел, 55/20",
        },
        {
          title: "Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»",
          text: "Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.",
          date:"05.07.2024 - 07.07.2024 гг",
          address:"г. Астана, пр. Мангилик Ел, 55/20",
        }
      ],
      currentReview: "",
      backUrl: window.API_ROOT + '/storage/',
    }
  }
}
</script>
<style>

.events-block {
  background: linear-gradient(90deg, #32436d 0%, #02c7fa 100%);
  padding-top: 75px;
}

.events-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

.events-title-line {

}

.events-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.6px;
  color: #FFF;
  margin-top: 50px;
  margin-bottom: 40px;
  font-family: "Montserrat", sans-serif;
}

.forums-section {
  height: 700px;
  padding: 80px 0;
  background-repeat: no-repeat;
  background-image: url("/images/forum-section-img.png");
  background-size: 100% auto;
  position: relative;
}

.forums-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #32436DA8;
  z-index: 0;
}

.all-forums {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #FFF;
  border-bottom: 1.5px solid #FFF;
  display: inline-flex;
  width: 125px;
  text-transform: uppercase;
  margin-bottom: 21px;
  position: relative;
  z-index: 10;
}

a {
  text-decoration: none;
}

a:hover {
  color: #FFF;
  text-decoration: none;
}

.forum-slide-item {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.forum-slide-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-details {
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #FFF;
  text-shadow: 1px 1px 2px black
}

.forum-slide-details span {
  font-weight: 600;
}

.forum-carousel-block .VueCarousel-navigation-prev {
  color: white !important;
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("/images/slider-left.svg");
  font-size: 0;
}

.forum-carousel-block .VueCarousel-navigation-prev:focus {
  outline: none;
}

.forum-carousel-block .VueCarousel-navigation-next {
  color: white !important;
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("/images/slider-right.svg");
  font-size: 0;
}

.forum-carousel-block .VueCarousel-navigation-next:focus {
  outline: none;
}

.trainings-section-desc {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}

.trainings-section-left {
  width: 68%;
}

.trainings-section-right {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.trainings-section {
  background: #FFF;
  position: relative;
}

.trainings-section::before {
  position: absolute;
  content: "";
  height: 709px;
  width: 77px;
  top: -220px;
  left: 0;
  background-image: url("/images/forums-img-back.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.trainings-section::after {
  position: absolute;
  content: "";
  height: 294px;
  width: 421px;
  top: 680px;
  right: -150px;
  background-image: url("/images/forums-img-back2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.trainings-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.6px;
  color: #1090CB;
  margin-bottom: 40px;
  font-family: "Montserrat", sans-serif;
}

.trainings-section-text {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #585858;

}

.trainings-section-link {
  border: 1.33px solid #1090CB;
  border-radius: 13px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32.69px;
  color: #1090CB;
  padding: 12px 65px;
  display: inline-block;
  height: fit-content;
  transition: transform 0.5s ease;
}

.trainings-section-link:hover {
  color: #1090CB;
  transform: scale(1.05);
}

.forums-list {
  display: flex;
  padding-bottom: 100px;
  gap: 20px;
}

.forums-item {
  width: 32%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;

}

.forums-item-img {
  height: 210px;
  overflow: hidden;
  border-radius: 6px;
}

.forums-item-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #9497A1;
  padding: 0 28px;
}

.forums-item-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #32436D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 28px;
  font-family: "Montserrat", sans-serif;
}

.forums-item-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding: 0 28px;
}

.forums-item-address {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #1090CB;
  padding: 0 28px;
  padding-bottom: 28px;
}


</style>
